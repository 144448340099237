import React, { useState } from 'react'
import { SocialLogins, useAuth } from 'gatsby-theme-firebase'
import Layout from '../components/layout/layout'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import { navigate, graphql } from 'gatsby'
import { Row, Modal } from 'react-bootstrap'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import i18next from 'i18next'

export default function Login({data}) {

  const { isLoggedIn } = useAuth()

  const [loginAlert, setLoginAlert] = useState({showModal: false, text: ''})

  function loginError(err) {
    setLoginAlert({showModal: true, text: err.message})
    setTimeout(() => {
      setLoginAlert({showModal: false, text: ''})
    }, 5000)
  }

  // l10n
  let storyPage

  if (i18next.language === 'cs') {
    storyPage = JSON.parse(data.page.edges[0].node.content)
  } else {
    storyPage = JSON.parse(data.page.edges[1].node.content)
  }

  return (
    <Layout>
      <GatsbySeo
        titleTemplate={storyPage.title + ' | %s'}
      />
      <div className="login-background">
        <Modal show={loginAlert.showModal}>
          <Modal.Header closeButton>
            <Modal.Title>Login error</Modal.Title>
          </Modal.Header>
          <Modal.Body>{loginAlert.text}</Modal.Body>
        </Modal>
        {isLoggedIn ? (
          <div>
            { navigate('/') }
          </div>
        ) : (
          <div>
            <Row className="justify-content-center my-3">
              <div className="water">
                <div className="ripple-one"></div>
                <div className="ripple-two"></div>
                <div className="ripple-three"></div>
                <div className="text">
                  <div className="pb-5" style={{fontSize: '25px'}}>
                    <p>{storyPage.body[0].title}</p>
                  </div>
                  <div className="pt-5 pb-2" style={{width: '210px', margin: 'auto'}}>
                    <SocialLogins
                      onSuccess={user => {
                        navigate('/')
                      }}
                      onError={err => {
                        loginError(err)
                      }}
                    />
                  </div>
                  <div style={{fontSize: '10px', marginTop: '15px', textShadow: 'none', width: '80%', margin: 'auto'}}>
                     <p><ReactMarkdown plugins={[gfm]} children={storyPage.body[0].text} /></p>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query LoginQuery {
    page: allStoryblokEntry(filter: {slug: {eq: "login"}})  {
      edges {
        node {
          content
        }
      }
    }
  }
`
